import {
    ClientDetailsPayloadInterface,
    InteractionPayloadType
} from 'storeTypes'

import { getLastMondayDate } from './getLastMondayDate'
import { isFarmerAwareness } from './interactions/interactionHelper'
import {
    buildClientFilter,
    buildPotentialClientFilter,
    LAST_30TH_DAY,
    TWO_YEARS_APART
} from 'pages/village/villageFilterOptions'

const isThisWeek = (date: string | undefined) => {
    if (!date) {
        return false
    }

    return Date.parse(date) >= getLastMondayDate().getTime()
}

export const activeClientList = (client: ClientDetailsPayloadInterface) =>
    new Date(client.last_voucher_date).getTime() >= LAST_30TH_DAY &&
    client.balance < client.all_enrollment

export const inactiveClientList = (client: ClientDetailsPayloadInterface) =>
    new Date(client.last_voucher_date).getTime() < LAST_30TH_DAY &&
    client.all_enrollment - client.balance > 0

export const completedPaymentClientList = (
    client: ClientDetailsPayloadInterface
) => client.all_enrollment - client.balance <= 0

export const neverDeliveredClients = (client: ClientDetailsPayloadInterface) =>
    !client.recent_year_delivered

export const beforeYearPrevClients = (client: ClientDetailsPayloadInterface) =>
    client.recent_year_delivered &&
    client.recent_year_delivered < TWO_YEARS_APART

export const clientByLastDelivered = (selectedYear: number) => {
    return (client: ClientDetailsPayloadInterface) =>
        client.recent_year_delivered === selectedYear
}

export const prevClientPayment = (client: ClientDetailsPayloadInterface) =>
    (client.smss || []).length > 0

export const visitedThisWeek =
    (interactions: InteractionPayloadType[]) =>
    (client: ClientDetailsPayloadInterface) => {
        return interactions.some((interaction) => {
            return (
                interaction.client_code === client.client_code &&
                isFarmerAwareness(interaction) &&
                isThisWeek(interaction.date_submitted || interaction.date)
            )
        })
    }

export const visitedAnytime = (interactions: InteractionPayloadType[]) => {
    return (client: ClientDetailsPayloadInterface) => {
        return interactions.some((interaction) => {
            return (
                interaction.client_code === client.client_code &&
                isFarmerAwareness(interaction)
            )
        })
    }
}

export const visitedNever = (interactions: InteractionPayloadType[]) => {
    return (client: ClientDetailsPayloadInterface) => {
        return !visitedAnytime(interactions)(client)
    }
}

export const packageNoPaymentClients = (
    client: ClientDetailsPayloadInterface
) => client.all_enrollment > 0 && client.balance <= 0 && !client.duplicate

export const paymentNoPackageClients = (
    client: ClientDetailsPayloadInterface
) => client.all_enrollment === 0 && client.balance > 0 && !client.duplicate

export const buildClientFilters = (
    interactions: InteractionPayloadType[],
    t: (key: string) => string
) => {
    const clientFilter = buildClientFilter(t)
    const potentialClientFilter = buildPotentialClientFilter(t)

    return {
        [clientFilter.ACTIVE]: activeClientList,
        [clientFilter.INACTIVE]: inactiveClientList,
        [clientFilter.COMPLETED_PAYMENT]: completedPaymentClientList,
        [clientFilter.PACKAGE_NO_PAYMENT]: packageNoPaymentClients,
        [potentialClientFilter.NEVER_DELIVERED]: neverDeliveredClients,
        [potentialClientFilter.BEFORE_YEAR]: beforeYearPrevClients,
        [potentialClientFilter.CLIENT_PAYMENT]: prevClientPayment,
        previousYear: clientByLastDelivered,
        [potentialClientFilter.VISITED_THIS_WEEK]:
            visitedThisWeek(interactions),
        [potentialClientFilter.VISITED]: visitedAnytime(interactions),
        [potentialClientFilter.NOT_VISITED]: visitedNever(interactions),
        [potentialClientFilter.PAYMENT_NO_PACKAGE]: paymentNoPackageClients
    }
}
