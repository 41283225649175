import React from 'react'
import { NetworkBarSvgs, NotificationMessageIcon } from 'assets'
import { t } from 'i18next'
import { useBaseNavigation } from 'hooks/useBaseNavigation'

export const navBarConfig = () => {
    const navigate = useBaseNavigation()
    const { HomeIcon, VillagesIcon, ProfileIcon } = NetworkBarSvgs

    const mobileMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: <HomeIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: <VillagesIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/village')
        },
        {
            name: t('messages'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: <NotificationMessageIcon />,
            isVisible: true,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: <ProfileIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        }
    ]

    const desktopMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: <HomeIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: <VillagesIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/village')
        },
        {
            name: t('messages'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: <NotificationMessageIcon />,
            isVisible: true,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: <ProfileIcon />,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        }
    ]

    return { mobileMenuConfig, desktopMenuConfig }
}
