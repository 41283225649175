import React, { FC } from 'react'
import { ClientDetailsPayloadInterface } from 'storeTypes'

type ClientDetailsProps = {
    profileIcon?: JSX.Element | null
    client: ClientDetailsPayloadInterface
    genderIcon?: JSX.Element | null
    showMultipleIcons?: boolean
}
export const ClientDetails: FC<ClientDetailsProps> = ({
    profileIcon,
    genderIcon,
    client,
    showMultipleIcons = true
}) => {
    return (
        <div className="name-abr-id">
            <div className="client-detail-profile" aria-label="client-profile">
                {showMultipleIcons && (
                    <div
                        className={`client-detail-profile--${
                            !genderIcon && 'empty-avatar'
                        }`}
                        aria-label="gender-avatar"
                    >
                        {genderIcon}
                    </div>
                )}
                <div
                    className={`card-client-detail--${
                        profileIcon ? 'icons' : 'no-icon'
                    }`}
                >
                    {profileIcon}
                </div>
            </div>
            <div className="">
                <div className="card-client-detail--name-icon">
                    <h2 className="card-client-detail--name">
                        {client?.fullname}{' '}
                    </h2>
                </div>
                <p className="card-client-detail--id">
                    ID {client?.client_code}
                </p>
            </div>
        </div>
    )
}
