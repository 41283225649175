import { ActionTypes } from 'actions'
import {
    VillageTransactionsStateType,
    TransactionsActionType
} from 'storeTypes'

const initState: VillageTransactionsStateType = {}

export const transactionsReducer = (
    state: VillageTransactionsStateType = initState,
    action: TransactionsActionType
): VillageTransactionsStateType => {
    switch (action.type) {
        case ActionTypes.FETCHING_TRANSACTIONS:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: true
                }
            }
        case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: false,
                    transactions: action.payload as []
                }
            }
        case ActionTypes.FETCH_TRANSACTIONS_FAILURE:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: false,
                    error: action.error as string,
                    transactions: action.payload as []
                }
            }
        case ActionTypes.FETCH_TRANSACTIONS_COMPLETE:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: false,
                    transactions: action.payload as []
                }
            }
        default:
            return state
    }
}
