import { Dispatch } from 'react'
import {
    FcMetricsEnum,
    FcMetricsPayload,
    FetchFcMetricsAction
} from '../storeTypes'
import connectApi from '../api/connectApi'

import { constants } from 'helpers'
const { FC_METRICS } = constants.endpoints

export const fcMetricsAction =
    (fcCode: string) => async (dispatch: Dispatch<FetchFcMetricsAction>) => {
        dispatch({ type: FcMetricsEnum.FETCHING_FC_METRICS })
        try {
            const response = await connectApi.get(FC_METRICS, {
                params: { fc_code: fcCode }
            })
            const data: FcMetricsPayload = response.data
            dispatch({
                type: FcMetricsEnum.FETCH_FC_METRICS_SUCCESS,
                payload: data
            })
        } catch (error: unknown) {
            const errorMessage =
                error instanceof Error ? error.message : 'An error occurred'
            console.error('Error fetching FC Metrics:', errorMessage)

            dispatch({
                type: FcMetricsEnum.FETCH_FC_METRICS_FAILURE,
                payload: errorMessage
            })
        }
    }
