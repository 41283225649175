import { ClientInteractions, InteractionOutcomes } from '../types'
import { ClientStatus } from '../storeTypes'
import {
    enrollIcon,
    notInterestedIcon,
    followUpIcon,
    cardPaymentIcon,
    mobileMoneyPaymentIcon,
    flagIcon
} from 'assets'

const enrollmentInteraction = {
    name: ClientInteractions.ENROLL,
    icon: enrollIcon,
    outcome: InteractionOutcomes.ENROLLED
}

const cardPaymentInteraction = {
    name: ClientInteractions.CARD_PAYMENT,
    icon: cardPaymentIcon,
    outcome: InteractionOutcomes.CARD_PAYMENT
}

const mobileMoneyPaymentInteraction = {
    name: ClientInteractions.MOBILE_MONEY_PAYMENT,
    icon: mobileMoneyPaymentIcon,
    outcome: InteractionOutcomes.MOBILE_MONEY_PAYMENT
}

const requestFollowUpInteraction = {
    name: ClientInteractions.REQUEST_FOLLOW_UP,
    icon: followUpIcon,
    outcome: InteractionOutcomes.FOLLOW_UP
}

const notInterestedInteraction = {
    name: ClientInteractions.NOT_INTERESTED,
    icon: notInterestedIcon,
    outcome: InteractionOutcomes.NOT_INTERESTED
}

const duplicateFlagInteraction = {
    name: ClientInteractions.DUPLICATE_FLAG,
    icon: flagIcon(),
    outcome: InteractionOutcomes.DUPLICATE
}

const CLIENT_STATUSES_TO_INTERACTIONS = {
    [ClientStatus.ENROLLED]: [
        cardPaymentInteraction,
        mobileMoneyPaymentInteraction,
        requestFollowUpInteraction,
        duplicateFlagInteraction
    ],
    [ClientStatus.PACKAGE_WITHOUT_PAYMENT]: [
        { ...cardPaymentInteraction, outcome: InteractionOutcomes.ENROLLED },
        {
            ...mobileMoneyPaymentInteraction,
            outcome: InteractionOutcomes.ENROLLED
        },
        requestFollowUpInteraction,
        duplicateFlagInteraction
    ],
    [ClientStatus.PAYMENT_WITHOUT_PACKAGE]: [
        enrollmentInteraction,
        requestFollowUpInteraction,
        notInterestedInteraction,
        duplicateFlagInteraction
    ],
    [ClientStatus.POTENTIAL]: [
        enrollmentInteraction,
        requestFollowUpInteraction,
        notInterestedInteraction,
        duplicateFlagInteraction
    ],
    [ClientStatus.FINISHED]: [
        requestFollowUpInteraction,
        notInterestedInteraction
    ],
    [ClientStatus.DUPLICATE]: []
}

export const getInteractionsFromClientStatus = (clientStatus: ClientStatus) => {
    return CLIENT_STATUSES_TO_INTERACTIONS[clientStatus] || []
}

export const interactionOutcomeStyles = {
    [InteractionOutcomes.FOLLOW_UP]: {
        color: '#16A34A',
        btnStyle: 'info',
        className: 'follow_up'
    },
    [InteractionOutcomes.NOT_INTERESTED]: {
        color: '#16A34A',
        btnStyle: 'light',
        className: 'not_interested'
    },
    [InteractionOutcomes.DUPLICATE]: {
        color: '#6B6B6B',
        btnStyle: 'light',
        className: 'duplicate'
    },
    default: {
        color: '#A96778',
        btnStyle: 'warning',
        className: 'default'
    }
}
