import React, { FC } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'

import {
    Home,
    CardSale,
    Village,
    Ranking,
    Deposits,
    VillageSelection,
    VillageClientDetails,
    VillageClient,
    ClientPaymentHistory,
    VillagePrevClientDetails,
    Calcapp,
    Visit,
    GPSWarning,
    EnrollmentClientDetails,
    ClientSearch,
    ClientSearchResult,
    PackageSelectionPage,
    FarmerIdQr,
    PaymentSelect,
    Profile,
    Notification
} from '../../pages'
import EnrollmentBase from 'pages/enrollment/v3/EnrollmentBase'

const ConnectRoutes = () => {
    const HomePage = Home as FC
    const VillagePage = Village as FC
    const VillagePrevClientDetailsPage = VillagePrevClientDetails as FC

    return (
        <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Navigate replace to="home" />} />
            <Route path="home" element={<HomePage />} />
            <Route path="home" element={<Outlet />}>
                <Route path="ranking" element={<Ranking />} />
            </Route>
            <Route path="home" element={<Outlet />}>
                <Route path="deposits" element={<Deposits />} />
            </Route>
            <Route path="cards" element={<Outlet />}>
                <Route path="card-sale" element={<CardSale />} />
            </Route>
            <Route path="village" element={<VillagePage />} />
            <Route path="village" element={<Outlet />}>
                <Route path="select-village" element={<VillageSelection />} />
                <Route path=":villageId" element={<VillageClient />} />
                <Route path=":villageId" element={<Outlet />}>
                    <Route
                        path="client/:clientId"
                        element={<VillageClientDetails />}
                    />
                    <Route path="client/:clientId" element={<Outlet />}>
                        <Route
                            path="payment-history"
                            element={<ClientPaymentHistory />}
                        />
                        <Route
                            path="previous"
                            element={<VillagePrevClientDetailsPage />}
                        />
                    </Route>
                </Route>
            </Route>
            <Route path="enrollment" element={<Outlet />}>
                <Route
                    path="package-selection"
                    element={<PackageSelectionPage />}
                />
                <Route
                    path="client-details"
                    element={<EnrollmentClientDetails />}
                />
            </Route>
            <Route path="calcapp" element={<Calcapp />} />
            <Route path="gpswarning" element={<GPSWarning />} />
            <Route path="visit" element={<Visit />} />
            <Route path="client-search" element={<ClientSearch />} />
            <Route path="client-search" element={<Outlet />}>
                <Route path="result" element={<ClientSearchResult />} />
            </Route>
            <Route path="notification" element={<Outlet />}>
                <Route path="payment-notification" element={<Notification />} />
            </Route>
            <Route path="/enroll/:villageId" element={<EnrollmentBase />} />
            <Route path="farmer-id" element={<FarmerIdQr />} />
            <Route path="payment-selection" element={<PaymentSelect />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile" element={<Outlet />}>
                <Route path="deposits" element={<Deposits />} />
            </Route>
        </Route>
    )
}

export default ConnectRoutes
