// See: src/scss/abstract/_variables.scss
export const colors = {
    primary: '#16A34A',
    white: '#FFFFFF',
    red: '#E85B5B',
    grey: '#979797',
    purple: '#9263D0',
    lightGreen: '#81D219',
    black: '#000',
    blue: '#2C70E3'
}
