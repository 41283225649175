import { TransactionPayloadType } from './transactionsTypes'

export enum ClientSearchEnum {
    FETCH_CLIENT_SEARCH = 'fetch client search',
    FETCHING_CLIENT_SEARCH = 'fetching client search',
    CLEAR_CLIENT_SEARCH = 'clear client search'
}

export enum ClientSearchFilter {
    VILLAGE = 'village'
}

export type ClientSearchType = {
    clientId?: string
    firstName?: string
    lastName?: string
    fullName?: string
    primaryPhone?: string
}

type GoalItemProduct = {
    product: {
        name: string
    }
}

export type ClientType = {
    age: null
    all_enrollment: number
    allEnrollment: number
    balance: number
    birth_year: null
    cash_account: string
    cash_balance: number
    certified_for_delivery: string
    client_code: string
    client_status: string
    country: string
    country_sharing_rule: string
    date: string
    date_created: string
    date_modified: number
    duplicate: boolean
    firstname: string
    fullname: string
    form_type: string
    goal_items?: GoalItemProduct[]
    group_leader: boolean
    group_role: string
    head_of_household: boolean
    last_season_goal_item: string
    last_voucher_date: string
    lastname: string
    mobile_money_phone: string
    phone: string
    phone_2: string
    phone_owner: string
    recent_year_delivered: string
    recent_year_enrolled: string
    reimbursement_choice: string
    savings_group_name: string
    sex: string
    sf_id: string
    smss?: TransactionPayloadType[]
    username: string
    uuid: string
    village: string
    village_id: string
    visit_requested: boolean
    zone: string
    _is_deleted?: boolean
}

type ClientSearchPayloadType = {
    allClientList: ClientType[]
    searchParams: ClientSearchType
}

export interface ClientSearchInterface {
    searchValue: ClientSearchType
    villageIds?: string[]
}

type FetchClientSearchType = {
    type: ClientSearchEnum.FETCH_CLIENT_SEARCH
    payload: ClientSearchPayloadType
}

type FetchingClientSearchType = {
    type: ClientSearchEnum.FETCHING_CLIENT_SEARCH
}

type ClearClientSearchType = {
    type: ClientSearchEnum.CLEAR_CLIENT_SEARCH
}

export type ClientSearchActionTypes =
    | FetchClientSearchType
    | FetchingClientSearchType
    | ClearClientSearchType
