/**
 * Function to sort an array of
 * objects alphabetically
 * @param {Array} data
 * @param {string} searchKey
 */

import { constants } from './constants'

const { SORT_DIRECTION, SORT_TYPE } = constants

export const sortHandler = (
    data,
    searchKey,
    sortType = SORT_TYPE.ALPHA_NUMERIC,
    sortDirection = SORT_DIRECTION.ASCENDING
) => {
    const isDescending = sortDirection === SORT_DIRECTION.ASCENDING

    return data?.length > 0
        ? data?.sort(function (a, b) {
              if (sortType !== SORT_TYPE.ALPHA_NUMERIC) {
                  return isDescending
                      ? new Date(a[searchKey]) - new Date(b[searchKey])
                      : new Date(b[searchKey]) - new Date(a[searchKey])
              } else {
                  a = isNaN(a[searchKey])
                      ? a[searchKey]
                      : parseFloat(a[searchKey])
                  b = isNaN(b[searchKey])
                      ? b[searchKey]
                      : parseFloat(b[searchKey])
                  if (a > b) {
                      return isDescending ? 1 : -1
                  }

                  if (a < b) {
                      return isDescending ? -1 : 1
                  }
              }

              return 0
          })
        : []
}
