import connectApi from 'api/connectApi'
import { constants, generateUuidv4, internetConnect } from 'helpers'
import { Dispatch } from 'react'
import {
    TargetPayloadType,
    InteractionPayloadType,
    InteractionActionTypes
} from '../storeTypes'
import { ActionTypes } from './ActionTypes'
import { getInteractionLocation } from 'helpers/interactions/interactionHelper'
import { resolveInteractions } from '../datastores/ConflictResolution'

import {
    sendMessageToWorker,
    sendMessageToWorkerWithResponse
} from '../helpers/communication'

import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from '../datastores/LocalDataExpiration'
import {
    disablePreventRefresh,
    enablePreventRefresh
} from 'helpers/preventRefresh'

export const sendInteractionsAction =
    (interaction: InteractionPayloadType) =>
    async (dispatch: Dispatch<InteractionActionTypes>) => {
        try {
            enablePreventRefresh()

            const { INTERACTIONS } = constants.endpoints
            const interactionPayload: InteractionPayloadType = {
                survey_data: {},
                ...interaction,
                date: new Date().toISOString(),
                id: generateUuidv4()
            }

            if (
                interactionPayload.survey_name ===
                constants.TARGET_LIST_SURVEY_NAME
            ) {
                const { fetchedTargetList } =
                    await sendMessageToWorkerWithResponse({
                        'get target list': {}
                    })

                const foundTarget = fetchedTargetList.find(
                    (target: TargetPayloadType) =>
                        target.client_code === interactionPayload.client_code
                )

                if (foundTarget) {
                    interactionPayload.target_uuid = foundTarget.uuid
                }
            }

            sendMessageToWorker({
                'store interaction': interactionPayload
            })

            const location =
                (await getInteractionLocation()) as GeolocationCoordinates
            if (location) {
                interactionPayload.location = location
            }

            const response = await connectApi.post(
                INTERACTIONS,
                interactionPayload
            )

            if (response?.status === 200) {
                return dispatch({
                    type: ActionTypes.RECORD_INTERACTION_SUCCESS,
                    villageId: interactionPayload.village_id
                })
            }

            dispatch({
                type: ActionTypes.RECORD_INTERACTION_FAILURE,
                payload: response?.data?.message,
                villageId: interactionPayload.village_id
            })
        } catch (error: unknown) {
            dispatch({
                type: ActionTypes.RECORD_INTERACTION_FAILURE,
                villageId: interaction.village_id
            })
        } finally {
            disablePreventRefresh()
        }
    }

const serializeList = (list: string[], name: string) => {
    if (list.length === 0) return ''
    return list.map((item) => `${name}=${item}`).join('&')
}

const fetchInteractionsFromServer = async (
    villageIds: string[],
    clientCodes: string[],
    surveyNames?: string[],
    surveyVersion?: string
) => {
    const { INTERACTIONS } = constants.endpoints
    const serializedVillageIds = serializeList(villageIds, 'village_ids')
    const serializedClientCodes = serializeList(clientCodes, 'client_codes')
    let url = `${INTERACTIONS}?${serializedVillageIds}&${serializedClientCodes}`
    if (surveyNames) {
        const serializedSurveyNames = serializeList(surveyNames, 'survey_names')
        url += serializedSurveyNames
    }
    if (surveyVersion) {
        url += `&survey_version=${surveyVersion}`
    }
    const response = await connectApi.get(url)
    return response.data as InteractionPayloadType[]
}

export const fetchInteractionsAction =
    ({
        villageIds = [],
        surveyNames,
        surveyVersion
    }: {
        villageIds?: string[]
        surveyNames?: string[]
        surveyVersion?: string
    }) =>
    async (dispatch: Dispatch<InteractionActionTypes>) => {
        if (villageIds.length === 0) {
            return dispatch({
                type: ActionTypes.FETCH_INTERACTIONS_FAILURE,
                surveyNames,
                surveyVersion,
                payload: 'Un problème est survenu'
            })
        }
        const { storedData: localInteractions } =
            await sendMessageToWorkerWithResponse({
                'get interactions': {
                    villageIds
                }
            })
        const villageToUpdate = []
        let shouldUpdateTargetList = false
        if (localInteractions.length > 0) {
            villageIds.forEach((villageId) => {
                dispatch({
                    type: ActionTypes.FETCH_INTERACTIONS_SUCCESS,
                    surveyNames,
                    surveyVersion,
                    payload: localInteractions.filter(
                        (interaction: InteractionPayloadType) =>
                            interaction.village_id === villageId
                    ),
                    villageId
                })
            })
            if (internetConnect()) {
                if (villageIds.length > 0) {
                    villageIds.forEach((villageId) => {
                        const isDataExpired = hasLocalDataExpired(
                            constants.INTERACTIONS_EXPIRATION_NAME,
                            villageId
                        )
                        if (isDataExpired) {
                            villageToUpdate.push(villageId)
                        }
                    })
                } else {
                    shouldUpdateTargetList = hasLocalDataExpired(
                        constants.INTERACTIONS_EXPIRATION_NAME,
                        'target_list'
                    )
                }
            }
        } else {
            villageToUpdate.push(...villageIds)
            shouldUpdateTargetList = true
            villageToUpdate.forEach((villageId) => {
                dispatch({
                    type: ActionTypes.FETCHING_INTERACTIONS,
                    surveyNames,
                    surveyVersion,
                    villageId
                })
            })
        }
        if (villageToUpdate.length > 0 || shouldUpdateTargetList) {
            try {
                const remoteInteractions = await fetchInteractionsFromServer(
                    villageToUpdate,
                    [],
                    surveyNames,
                    surveyVersion
                )
                const interactions = resolveInteractions(
                    localInteractions,
                    remoteInteractions
                )
                sendMessageToWorker({
                    'store interactions': interactions
                })

                villageToUpdate.forEach((villageId) => {
                    updateLocalDataExpirationTime(
                        constants.INTERACTIONS_EXPIRATION_NAME,
                        villageId,
                        constants.INTERACTIONS_EXPIRATION_DURATION
                    )
                })
                if (shouldUpdateTargetList) {
                    updateLocalDataExpirationTime(
                        constants.INTERACTIONS_EXPIRATION_NAME,
                        'target_list',
                        constants.INTERACTIONS_EXPIRATION_DURATION
                    )
                }
                villageToUpdate.forEach((villageId) => {
                    dispatch({
                        type: ActionTypes.FETCH_INTERACTIONS_SUCCESS,
                        surveyNames,
                        surveyVersion,
                        payload: interactions.filter(
                            (interaction) =>
                                interaction.village_id === villageId
                        ),
                        villageId
                    })
                })
            } catch (error: unknown) {
                villageIds.forEach((villageId) => {
                    dispatch({
                        type: ActionTypes.FETCH_INTERACTIONS_FAILURE,
                        surveyNames,
                        surveyVersion,
                        payload: 'Un problème est survenu',
                        villageId
                    })
                })
            }
        }
    }

export const getVeInteractionsAction =
    ({
        villageIds = [],
        clientCodes = [],
        surveyName,
        surveyVersion
    }: {
        villageIds?: string[]
        clientCodes?: string[]
        surveyName?: string
        surveyVersion?: string
    }) =>
    async (dispatch: Dispatch<InteractionActionTypes>) => {
        if (villageIds.length === 0 && clientCodes.length === 0) {
            return dispatch({
                type: ActionTypes.GET_INTERACTIONS_FAILURE,
                payload: 'Un problème est survenu'
            })
        }
        const { storedData: localInteractions } =
            await sendMessageToWorkerWithResponse({
                'get interactions': {
                    villageIds,
                    clientCodes,
                    surveyName,
                    surveyVersion
                }
            })
        dispatch({
            type: ActionTypes.GET_INTERACTIONS_SUCCESS,
            payload: localInteractions
        })
    }
