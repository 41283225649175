import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { buildClientUrl, constants, getConnectUser, VillageType } from 'helpers'
import {
    useScreenSize,
    useBindDispatch,
    useAppSelector,
    useQrScanner
} from 'hooks'
import './farmerId.css'
import { Container, Row, Col, TimedAlert, Modal, TopBar } from 'components'
import { ClientType } from 'storeTypes'
import { FarmerIdModal } from './FarmerIdModal'

export const FarmerIdQr = () => {
    const ref = useRef<HTMLVideoElement>(null)
    const { t } = useTranslation()
    const [validationError, setValidationError] = useState('')
    const navigate = useNavigate()
    const { navbarHandler } = useBindDispatch()
    const {
        clientSearchHandler,
        selectedClientHandler,
        getClientOrdersHandler,
        displayFarmerIdModalHandler,
        villageSelectionInfoHandler,
        setFarmerIdQrDataHandler,
        clearClientSearchHandler
    } = useBindDispatch()
    const screenSize = useScreenSize()
    const { country, veVillages } = getConnectUser()
    const [qrData, setQrData] = useState<string>('')

    const { searchResult } = useAppSelector((state) => state.clientSearch) as {
        searchResult: ClientType[]
    }

    const { clientOrders } = useAppSelector((state) => state.clientOrders)

    const { displayModal, farmerIdQrData } = useAppSelector(
        (state) => state.displayFarmerIdModal
    )

    const villageIds =
        veVillages?.map((village: VillageType) => village.sf_id) || []

    const qrDataHandler = (data: string) => {
        setQrData(`${data}_${Date.now()}`)
    }

    const { qrScannerError } = useQrScanner({
        ref,
        qrDataState: qrDataHandler as React.Dispatch<
            React.SetStateAction<string>
        >
    })

    const isMobile = screenSize.screenWidth < constants.MOBILE_SIZE

    const countryConfig = {
        Senegal: 'SN',
        Mali: 'ML'
    }

    const VIP = 'v'

    useEffect(() => {
        localStorage.removeItem(constants.CLIENT_FOR_ENROLLMENT_STORE)
        localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)
    }, [])

    // hide menu bar for mobile
    useEffect(() => {
        if (isMobile) {
            navbarHandler(constants.HIDE_PART_NAVBAR)
        } else {
            navbarHandler(constants.SHOW_NAVBAR)
        }
    }, [isMobile])

    const isQrCodeValid = (url: URL) => {
        const baseCardUrl = process.env.REACT_APP_QR_CODE_BASE_URL
        const qrCodeUrl = `${url.protocol}//${url.host}`

        return baseCardUrl === qrCodeUrl
    }

    useEffect(() => {
        if (!qrData) return

        if (!isQrCodeValid(new URL(qrData))) {
            return setValidationError(t('invalidQrCode'))
        }

        const url = new URL(qrData)
        const clientCode =
            url.searchParams.get('id') || url.searchParams.get('code')
        const farmerCardCountry = url.searchParams.get('c')

        setFarmerIdQrDataHandler({
            clientCode: clientCode as string,
            country: farmerCardCountry as string,
            type: url.searchParams.get('t') as string
        })

        if (
            farmerCardCountry !==
            countryConfig[country as keyof typeof countryConfig]
        ) {
            return setValidationError(t('invalidQrCode'))
        }

        clientSearchHandler({
            searchValue: {
                clientId: clientCode as string
            },
            villageIds
        })
    }, [qrData])

    const handleVillageRedirection = (clientCode: string) => {
        if (villageIds.length === 1) {
            return navigate(
                `/enroll/${villageIds[0]}?from=/farmer-id&farmerIdBlankId=${clientCode}`
            )
        }

        villageSelectionInfoHandler({
            url: (villageId: string) =>
                `/enroll/${villageId}?from=/farmer-id&farmerIdBlankId=${clientCode}`
        })
        return navigate('/village')
    }

    useEffect(() => {
        const sanitizedClientCode = farmerIdQrData?.clientCode?.trim()

        const hasFoundMatchingClientCode =
            searchResult?.length > 0 &&
            (searchResult[0]?.client_code === sanitizedClientCode ||
                !farmerIdQrData?.clientCode)

        if (hasFoundMatchingClientCode) {
            if ((searchResult[0]?.all_enrollment || 0) > 0) {
                getClientOrdersHandler(searchResult[0].client_code)
            }

            if (searchResult[0]?.duplicate) {
                const isClientPrevious =
                    (searchResult[0]?.all_enrollment || 0) <= 0
                selectedClientHandler({
                    clientData: searchResult[0],
                    clientType: isClientPrevious ? 'previous' : 'current'
                })
                navigate(`${buildClientUrl(searchResult[0])}?from=/farmer-id`, {
                    replace: true
                })
                clearClientSearchHandler()
                return
            }

            displayFarmerIdModalHandler(true)
            return
        }

        if (qrData && isQrCodeValid(new URL(qrData))) {
            if (farmerIdQrData?.type?.substring(0, 1) === VIP) {
                return setValidationError(t('invalidQrCode'))
            }
            handleVillageRedirection(sanitizedClientCode as string)
        }
    }, [searchResult])

    const farmerSearchClickHandler = () => {
        navigate('/client-search', { replace: true })
    }

    return (
        <>
            <div className="header-bar header-bar--transparent" id="header-bar">
                <Container>
                    <TopBar
                        back
                        backNavigation={() => navigate(-1)}
                        close={() => navigate('/home')}
                        iconColor="#000000"
                        transparentTopBar
                    >
                        {t('scanner')}
                    </TopBar>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="farmer-id--parent">
                            <div className="farmer-id--header-parent">
                                <h2 className="farmer-id--header">
                                    {t('scanCard')}
                                </h2>
                            </div>
                            <div>
                                <video
                                    ref={ref}
                                    className="farmer-id--video"
                                    data-testid="qr-camera"
                                />
                                <p className="scanner-label">
                                    {validationError ? (
                                        <span className="invalid-qr-error">
                                            {validationError}
                                        </span>
                                    ) : (
                                        `${t('scanQrCode')}...`
                                    )}
                                </p>
                            </div>
                            <div
                                className="farmer-id--manual-link-parent"
                                onClick={farmerSearchClickHandler}
                                aria-label="search-farmer-button"
                            >
                                <p className="farmer-id--search-text">
                                    {t('farmerSearch')}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {qrScannerError && (
                    <TimedAlert type="floating" status="error">
                        {qrScannerError}
                    </TimedAlert>
                )}

                {
                    <Modal
                        showModal={displayModal}
                        data-testid="farmer-id-modal"
                        position={'center'}
                        className="farmer-id--modal"
                        closeClickHandler={() =>
                            displayFarmerIdModalHandler(false)
                        }
                        showCloseIconBtn
                    >
                        {searchResult?.length > 0 && (
                            <FarmerIdModal
                                clientData={searchResult[0]}
                                selectClientHandler={selectedClientHandler}
                                clientOrders={clientOrders}
                                closeModalHandler={() =>
                                    displayFarmerIdModalHandler(false)
                                }
                            />
                        )}
                    </Modal>
                }
            </Container>
        </>
    )
}
