import React, { useEffect, useRef, useState } from 'react'
import { CircleMetrics, Col, Container, Row, Search, TopBar } from 'components'
import { useTranslation } from 'react-i18next'
import './fcHome.scss'
import { useOnChange, useBindDispatch } from 'hooks'
import { CSSTransition } from 'react-transition-group'
import { constants, getConnectUser } from 'helpers'
import { useSelector } from 'react-redux'
import { RootState } from 'store/configStore'

export const FcHome = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const inputRef = useRef(null)
    const nodeRef = useRef(null)
    const [changeIconColorTo, setChangeIconColorTo] = useState('')
    const { inputText, onChangeHandler } = useOnChange({
        query: ''
    })
    const fcData = useSelector((state: RootState) => state.fcMetrics?.data)
    const { t } = useTranslation()
    const { fcMetricsHandler } = useBindDispatch()
    const { staff_code: staffCode } = getConnectUser()

    const { ANIMATION_TIMEOUT } = constants

    useEffect(() => {
        setAnimate(true)
        if (!fcData && staffCode) {
            fcMetricsHandler(staffCode)
        }
    }, [fcData, staffCode])

    useEffect(() => {
        if (inputRef.current) {
            setChangeIconColorTo('#16A34A')
        }
    }, [inputText.query, inputRef.current])

    const metrics = [
        {
            label: 'Villages',
            value: fcData?.fc_metrics.total_villages
        },
        {
            label: 'VEs',
            value: fcData?.fc_metrics.total_ves
        },
        {
            label: 'Producteurs',
            value: fcData?.fc_metrics.total_farmers
        }
    ]

    return (
        <div>
            <Container>
                <TopBar iconColor="#000000" transparentTopBar>
                    {t('zone')} {fcData?.fc_info?.zone_name}
                </TopBar>
            </Container>
            <CSSTransition
                unmountOnExit
                timeout={ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                onEnter={() => setAnimationList(true)}
                nodeRef={nodeRef}
            >
                <div className="page-wrapper" ref={nodeRef}>
                    <Container>
                        <Row>
                            <Col
                                md={12}
                                className={
                                    animationList
                                        ? 'animate-showed-1'
                                        : 'animate-show-1'
                                }
                            >
                                <p
                                    className="fc-home--welcome"
                                    aria-label="welcome-header"
                                >
                                    {t('home.greetings')}
                                    {fcData?.fc_info?.name
                                        ? `, ${
                                              fcData?.fc_info.name?.split(
                                                  ' '
                                              )[0]
                                          }`
                                        : ''}
                                </p>
                            </Col>
                            <Col
                                md={12}
                                className={
                                    animationList
                                        ? 'animate-showed-4'
                                        : 'animate-show-4'
                                }
                            >
                                {<CircleMetrics metrics={metrics} />}
                            </Col>
                            <Col
                                md={12}
                                className={
                                    animationList
                                        ? 'animate-showed-6'
                                        : 'animate-show-6'
                                }
                            >
                                <div className="fc-home--search">
                                    <Search
                                        inputRef={inputRef}
                                        iconSize={'30'}
                                        iconColor={changeIconColorTo}
                                        iconPos="left"
                                        style="rounded"
                                        onChange={onChangeHandler}
                                        placeholder={t(
                                            'searchInputPlaceholder'
                                        )}
                                        data-testid="quick-search-input"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CSSTransition>
        </div>
    )
}
