import {
    Order,
    OrdersActionEnum,
    OrdersFetchActionType,
    OrdersStoreActionType
} from 'storeTypes'

type OrdersStateItem = {
    clientOrders?: Order[]
    loading?: boolean
    error?: string
}

type VillageClientOrdersState = {
    [key: string]: OrdersStateItem
}

const initialVillageOrdersState: VillageClientOrdersState = {}

export const villageClientOrdersReducer = (
    state = initialVillageOrdersState,
    action: OrdersStoreActionType
): VillageClientOrdersState => {
    switch (action.type) {
        case OrdersActionEnum.STORING_ORDERS:
            return {
                ...state,
                [action.villageId]: {
                    ...state[action.villageId],
                    loading: true,
                    error: ''
                }
            }
        case OrdersActionEnum.STORE_ORDERS:
            return {
                ...state,
                [action.villageId]: {
                    ...state[action.villageId],
                    loading: false,
                    error: '',
                    clientOrders: action.payload
                }
            }
        case OrdersActionEnum.FAIL_STORE_VILLAGE_ORDERS: {
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    clientOrders: [],
                    error: action.payload
                }
            }
        }
        default:
            return state
    }
}

const initialOrdersState: OrdersStateItem = {
    clientOrders: []
}

export const clientOrdersReducer = (
    state = initialOrdersState,
    action: OrdersFetchActionType
): OrdersStateItem => {
    switch (action.type) {
        case OrdersActionEnum.FETCH_CLIENT_ORDERS: {
            return {
                clientOrders: action.payload
            }
        }
        case OrdersActionEnum.FAIL_FETCH_CLIENT_ORDERS: {
            return {
                clientOrders: []
            }
        }
        case OrdersActionEnum.RESET_CLIENT_ORDERS: {
            return {
                clientOrders: []
            }
        }
        default:
            return state
    }
}
