import connectApi from '../api/connectApi'
import { constants } from 'helpers'
import { ActionTypes } from './ActionTypes'

export const getUserPermissions = () => async (dispatch) => {
    try {
        const { PERMISSIONS } = constants.endpoints

        const storedPermissions = localStorage.getItem(
            constants.USER_PERMISSIONS
        )

        if (storedPermissions) {
            dispatch({
                type: ActionTypes.FETCH_USER_PERMISSIONS,
                payload: JSON.parse(storedPermissions)
            })
        }

        const userPermissions = await connectApi.get(PERMISSIONS)

        // extract the data from the endpoint
        const { permissions } = userPermissions.data
        localStorage.setItem(
            constants.USER_PERMISSIONS,
            JSON.stringify(permissions)
        )
        return dispatch({
            type: ActionTypes.FETCH_USER_PERMISSIONS,
            payload: permissions
        })
    } catch (error) {
        return dispatch({
            type: ActionTypes.FAIL_USER_PERMISSIONS,
            payload: 'Something is wrong'
        })
    }
}
