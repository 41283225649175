import React from 'react'
import { searchIcon } from '../../assets'
import './search.css'

type SearchInterface = {
    inputRef?: React.RefObject<HTMLInputElement> | undefined
    showIcon?: boolean
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    value?: string
    id?: string
    iconPos?: 'right' | 'left'
    iconSize?: string
    iconColor?: string
    style?: 'default' | 'rounded'
    onSearchHandler?: () => void
}

export const Search: React.FC<SearchInterface> = ({
    inputRef,
    style = 'default',
    iconPos = 'right',
    iconSize,
    iconColor,
    onSearchHandler,
    ...props
}) => {
    const searchInput = (
        <input
            ref={inputRef}
            type="text"
            className={`search-input${style === 'default' ? '' : '--rounded'}`}
            onKeyDown={({ key }) => key === 'Enter' && onSearchHandler?.()}
            {...props}
        />
    )

    const searchInputBuild =
        iconPos === 'left' ? (
            <>
                <div
                    className="secondary-icon"
                    onClick={onSearchHandler}
                    data-testid="search-icon"
                >
                    {searchIcon({ size: iconSize, color: iconColor })}
                </div>{' '}
                {searchInput}
            </>
        ) : (
            <>
                {searchInput}{' '}
                <div className="search-input--btn" id="search-input-btn">
                    {searchIcon({})}
                </div>
            </>
        )

    return <span className="search--input">{searchInputBuild}</span>
}
