import React from 'react'
import { getConnectUser, staffRoles } from 'helpers'
import { NavigateOptions, useNavigate } from 'react-router-dom'

export const useBaseNavigation = (basePath = '') => {
    const navigate = useNavigate()
    const user = getConnectUser()

    if (user.role === staffRoles.FC) {
        basePath = '/fc'
    }

    const navigateWithBasePath = React.useCallback(
        (path: string, options?: NavigateOptions) => {
            navigate(`${basePath}${path}`, options)
        },
        [basePath, navigate]
    )

    return navigateWithBasePath
}
