import { ActionTypes } from 'actions'
import { mergeInteractions } from 'helpers/interactions/interactionHelper'
import {
    InteractionActionTypes,
    InteractionPayloadType,
    veInteractionsStateType,
    VillageInteractionsStateType
} from 'storeTypes/interactionsTypes'
import * as Sentry from '@sentry/react'

const initState: VillageInteractionsStateType = {}

export const interactionsReducer = (
    state: VillageInteractionsStateType = initState,
    action: InteractionActionTypes
): VillageInteractionsStateType => {
    switch (action.type) {
        case ActionTypes.RECORD_INTERACTION_SUCCESS: {
            if (!action.villageId) {
                Sentry.captureMessage(
                    'Interaction recorded with no villageID',
                    'error'
                )
            }
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    isInteractionRecorded: true
                }
            }
        }
        case ActionTypes.RECORD_INTERACTION_FAILURE:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    error: 'Un problème est survenu'
                }
            }
        case ActionTypes.FETCHING_INTERACTIONS:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: true
                }
            }
        case ActionTypes.FETCH_INTERACTIONS_SUCCESS:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: false,
                    interactions: mergeInteractions(
                        state[action.villageId ?? '']?.interactions,
                        action.payload
                    ) as InteractionPayloadType[]
                }
            }
        case ActionTypes.FETCH_INTERACTIONS_FAILURE:
            return {
                ...state,
                [action.villageId ?? '']: {
                    ...state[action.villageId ?? ''],
                    loading: false,
                    error: 'Un problème est survenu'
                }
            }
        default:
            return state
    }
}

const initialVeState: veInteractionsStateType = {
    interactions: [],
    error: ''
}

export const veInteractionsReducer = (
    state = initialVeState,
    action: InteractionActionTypes
): veInteractionsStateType => {
    switch (action.type) {
        case ActionTypes.GET_INTERACTIONS_SUCCESS:
            return {
                ...state,
                interactions: action.payload as InteractionPayloadType[]
            }
        case ActionTypes.GET_INTERACTIONS_FAILURE:
            return {
                ...state,
                error: action.payload as string
            }
        default:
            return state
    }
}
