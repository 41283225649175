import { useCallback, useEffect, useState } from 'react'
import OneSignal from 'react-onesignal'

export const useHandleNotificationPermissionChange = (
    currentPermission: NotificationPermission
): {
    areNotificationsAllowed: boolean
    updateOneSignalSubscription: () => Promise<void>
} => {
    const [areNotificationsAllowed, setAreNotificationsAllowed] =
        useState<boolean>(currentPermission === 'granted')

    const updateOneSignalSubscription = useCallback(async () => {
        if (currentPermission === 'default' || currentPermission === 'denied') {
            const permission =
                (await OneSignal.Notifications.requestPermission()) as unknown as NotificationPermission
            if (permission === 'granted') {
                setAreNotificationsAllowed(true)
            }
        } else {
            setAreNotificationsAllowed(true)
        }
    }, [])

    useEffect(() => {
        const permissionChangeListener = (status: boolean) => {
            setAreNotificationsAllowed(status)
        }

        OneSignal.Notifications.addEventListener(
            'permissionChange',
            permissionChangeListener
        )

        return () => {
            OneSignal.Notifications.removeEventListener(
                'permissionChange',
                permissionChangeListener
            )
        }
    }, [])

    return {
        areNotificationsAllowed,
        updateOneSignalSubscription
    }
}
