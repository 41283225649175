import { Dispatch } from 'redux'

import {
    sendMessageToWorker,
    sendMessageToWorkerWithResponse,
    internetConnect,
    constants,
    VillageType
} from 'helpers'
import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from 'datastores/LocalDataExpiration'
import connectApi from 'api/connectApi'
import {
    OrdersActionEnum,
    OrdersFetchActionType,
    OrdersStoreActionType
} from 'storeTypes'

export const getClientOrdersAction =
    (clientCode: string) =>
    async (dispatch: Dispatch<OrdersFetchActionType>) => {
        const { FAIL_FETCH_CLIENT_ORDERS, FETCH_CLIENT_ORDERS } =
            OrdersActionEnum

        try {
            const { result: clientOrders } =
                await sendMessageToWorkerWithResponse({
                    'get client orders': clientCode
                })

            dispatch({ type: FETCH_CLIENT_ORDERS, payload: clientOrders })
        } catch (error: unknown) {
            dispatch({
                type: FAIL_FETCH_CLIENT_ORDERS
            })
        }
    }

export const resetClientOrdersAction =
    () => (dispatch: Dispatch<OrdersFetchActionType>) => {
        dispatch({ type: OrdersActionEnum.RESET_CLIENT_ORDERS })
    }

export const storeVillageOrdersAction =
    (villages: VillageType[]) =>
    async (dispatch: Dispatch<OrdersStoreActionType>) => {
        const { CLIENT_ORDERS } = constants.endpoints
        const villageIdsToUpdate: string[] = []
        try {
            const internetConnection = internetConnect()
            villages.forEach((village) => {
                const isDataExpired = hasLocalDataExpired(
                    constants.CLIENT_LIST_EXPIRATION_NAME,
                    village.sf_id
                )
                if (isDataExpired) {
                    villageIdsToUpdate.push(village.sf_id)
                }
            })
            const params = villageIdsToUpdate
                .map((villageId) => `village_ids=${villageId}`)
                .join('&')

            if (!internetConnection || villageIdsToUpdate.length === 0) return
            villageIdsToUpdate.forEach((villageId) =>
                dispatch({
                    type: OrdersActionEnum.STORING_ORDERS,
                    villageId
                })
            )

            const response = await connectApi.get(`${CLIENT_ORDERS}?${params}`)

            if (response.status === 200) {
                villageIdsToUpdate.forEach((villageId) =>
                    updateLocalDataExpirationTime(
                        constants.CLIENT_LIST_EXPIRATION_NAME,
                        villageId
                    )
                )
                sendMessageToWorker({
                    'store client orders': response.data.data
                })
                villageIdsToUpdate.forEach((villageId) =>
                    dispatch({
                        type: OrdersActionEnum.STORE_ORDERS,
                        payload: response.data.data,
                        villageId
                    })
                )
            } else {
                throw new Error('Response status ' + response.status)
            }
        } catch (error) {
            villageIdsToUpdate.forEach((villageId) =>
                dispatch({
                    type: OrdersActionEnum.FAIL_STORE_VILLAGE_ORDERS,
                    villageId
                })
            )
        }
    }
