export type Order = {
    uuid: string
    client_code: string
    desired_units_display: string
    price: number
    hide_price: boolean
    year: string
    enrollment_date: string
    dropped: boolean
    delivered: boolean
    ve_delivered: boolean
    village_id: string
    product?: {
        sf_id: string
        id: string
        name: string
    }
    season_active: boolean
    desired_units?: string
    currency?: string
    pricebook_id?: string
}

export enum OrdersActionEnum {
    FETCHING_CLIENT_ORDERS = 'fetching orders',
    FETCH_CLIENT_ORDERS = 'fetch orders',
    STORE_ORDERS = 'store orders',
    STORING_ORDERS = 'storing orders',
    FAIL_FETCH_CLIENT_ORDERS = 'fail fetch orders',
    FAIL_STORE_VILLAGE_ORDERS = 'fail store orders',
    RESET_CLIENT_ORDERS = 'reset client orders'
}

type OrdersFetchSuccessActionType = {
    type:
        | OrdersActionEnum.FETCH_CLIENT_ORDERS
        | OrdersActionEnum.FETCHING_CLIENT_ORDERS
        | OrdersActionEnum.RESET_CLIENT_ORDERS
    payload?: Order[]
}

export type OrdersFetchActionType =
    | OrdersFetchSuccessActionType
    | OrdersFailActionType

type OrdersStoreSuccessActionType = {
    type: OrdersActionEnum.STORE_ORDERS | OrdersActionEnum.STORING_ORDERS
    payload?: Order[]
    villageId: string
}

type OrdersFailActionType = {
    type:
        | OrdersActionEnum.FAIL_FETCH_CLIENT_ORDERS
        | OrdersActionEnum.FAIL_STORE_VILLAGE_ORDERS
    payload?: string
    villageId?: string
}

export type OrdersStoreActionType =
    | OrdersStoreSuccessActionType
    | OrdersFailActionType
