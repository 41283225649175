import { ClientDetailsPayloadInterface } from '../storeTypes/enrollmentTypes'

export const hasClientDetailsChanged = (
    client1: Partial<ClientDetailsPayloadInterface>,
    client2: { [key: string]: string | boolean | number }
) => {
    if (!client2) {
        return true
    }
    const client1Keys = Object.keys(client1) as Array<string>
    const fieldsToCompare = [
        'firstname',
        'lastname',
        'age',
        'sex',
        'head_of_household',
        'group_leader',
        'phone',
        'phone2',
        'mobile_money_phone',
        'phone_owner',
        'reimbursement_choice'
    ]
    return client1Keys.some(
        (key) =>
            fieldsToCompare.includes(key) &&
            (client1[key] || client2[key]) &&
            client1[key] !== client2[key]
    )
}
