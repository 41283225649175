import connectApi from '../api/connectApi'
import {
    canUseRxDb,
    constants,
    sendMessageToWorker,
    sendMessageToWorkerWithResponse,
    fetchProductPictures
} from '../helpers'
import { Dispatch } from 'redux'
import {
    EnrollmentInterface,
    EnrollmentActionTypes,
    EnrollmentEnum,
    ClientDetailsPayloadInterface
} from '../storeTypes'
import { ZoneInfoTemplate } from '../components/enrollForm/enrollFormTypes'
import { ErrorResponse } from '../types'
import {
    GpsCoords,
    ProductInfo,
    SelectedPacket
} from 'components/enrollForm/enrollFormTypes'
import * as Database from 'database/Database'
import { Order } from 'storeTypes'
import { buildOrderEvents } from 'helpers/buildOrderEvents'

type ParamsType = {
    zone_sf_id?: string
}

export const enrollmentAction =
    ({ zoneSFID }: EnrollmentInterface) =>
    // eslint-disable-next-line space-before-function-paren
    async (dispatch: Dispatch<EnrollmentActionTypes>) => {
        const { ENROLLMENT } = constants.endpoints
        dispatch({ type: EnrollmentEnum.FETCHING_ENROLLMENT_FORM })
        try {
            const params: ParamsType = {}
            if (zoneSFID) {
                params.zone_sf_id = zoneSFID
            }
            // get product list for enrollment form
            const response = await connectApi.get(ENROLLMENT, {
                params
            })
            const listOfProductImageURLs = response.data.flatMap(
                (item: ZoneInfoTemplate) =>
                    item.product_list.map(
                        (product: ProductInfo) => product.image_url
                    )
            )
            fetchProductPictures(listOfProductImageURLs)
            dispatch({
                type: EnrollmentEnum.FETCH_ENROLLMENT_FORM,
                payload: response.data
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: EnrollmentEnum.FAIL_ENROLLMENT_REQUEST,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: EnrollmentEnum.FAIL_ENROLLMENT_REQUEST,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const storeEnrollmentFormAction =
    ({ zoneSFID }: EnrollmentInterface) =>
    // eslint-disable-next-line space-before-function-paren
    async () => {
        const { ENROLLMENT } = constants.endpoints
        try {
            const params: ParamsType = {}
            if (zoneSFID) {
                params.zone_sf_id = zoneSFID
            }
            // get product list for enrollment form
            const response = await connectApi.get(ENROLLMENT, {
                params
            })
            if (response.status === 200) {
                localStorage.setItem(
                    constants.ENROLLMENT_FORM_CACHED_NAME,
                    'true'
                )
            }
        } catch (response: unknown) {
            console.error(response)
        }
    }

export const updatePackagesAction =
    (
        clientDetails: {
            client_code: string
            all_enrollment?: string
            balance?: string
            client_status?: string
            goal_items?: Order[]
        } & EnrollmentInterface,
        existingOrders: Order[],
        newOrders: SelectedPacket[]
    ) =>
    async (dispatch: Dispatch<EnrollmentActionTypes>) => {
        try {
            const { ORDERS } = constants.endpoints

            dispatch({ type: EnrollmentEnum.UPDATING_PACKAGES })
            dispatch({
                type: EnrollmentEnum.ENROLLMENT_PAYLOAD,
                payload: clientDetails
            })

            sendMessageToWorker({
                'update client details with orders': clientDetails
            })

            const payloadReplica = {
                ...clientDetails
            }

            if (canUseRxDb()) {
                const database = await Database.get()
                const indexedClient = await database?.clients
                    .findOne({
                        selector: {
                            client_code: payloadReplica.client_code
                        }
                    })
                    .exec()

                const modificationData = {
                    all_enrollment: payloadReplica.all_enrollment,
                    balance: payloadReplica.balance,
                    client_status: payloadReplica.client_status,
                    goal_items: payloadReplica.goal_items
                }

                if (indexedClient) {
                    indexedClient.patch({ ...modificationData })
                }
            }

            const events = buildOrderEvents(
                existingOrders,
                newOrders,
                clientDetails.client_code
            )

            const dropPromises = events.drop.map(
                async (event) => await connectApi.put(ORDERS, event)
            )
            const createPromises = events.create.map(
                async (event) => await connectApi.post(ORDERS, event)
            )
            Promise.allSettled([...dropPromises, ...createPromises])

            dispatch({
                type: EnrollmentEnum.UPDATE_PACKAGES_SUCCESS
            })
        } catch (error) {
            if (error instanceof ErrorResponse) {
                return dispatch({
                    type: EnrollmentEnum.UPDATE_PACKAGES_FAIL,
                    payload: error.response.data.detail
                })
            } else {
                return dispatch({
                    type: EnrollmentEnum.UPDATE_PACKAGES_FAIL
                })
            }
        }
    }

const updateClientPayload = (clientPayload: {
    zone?: string
    village?: string
    zoneName?: string
    villageName?: string
    village_name?: string
}) => ({
    ...clientPayload,
    zone: clientPayload.zoneName || clientPayload.zone,
    village: clientPayload.village || clientPayload.village_name
})

const removeUndefinedFields = (
    obj: Partial<ClientDetailsPayloadInterface> | undefined
) => {
    if (obj) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined || obj[key] === null) {
                delete obj[key]
            }
        })
    }
    return obj
}

export const clientDetailsEnrollmentAction =
    ({ clientDetailsPayload }: EnrollmentInterface, gpsCoords: GpsCoords) =>
    async (dispatch: Dispatch) => {
        const { CLIENT_DETAILS } = constants.endpoints
        dispatch({ type: EnrollmentEnum.SUBMITTING_CLIENT_DETAILS_ENROLLMENT })
        try {
            clientDetailsPayload = removeUndefinedFields(clientDetailsPayload)
            if (clientDetailsPayload?.form_type === 'enrollment') {
                const blankIdResponse =
                    clientDetailsPayload?.farmerIdBlankId ||
                    (
                        await sendMessageToWorkerWithResponse({
                            'get blank id': 'client needs a blank id'
                        })
                    ).blank_id.code.toString()

                if (blankIdResponse) {
                    clientDetailsPayload.client_code = blankIdResponse
                    const clientVisitStore = localStorage.getItem(
                        constants.CLIENT_FOR_VISIT_STORE
                    )
                    if (clientVisitStore) {
                        const parsedVisitStorage = JSON.parse(clientVisitStore)
                        parsedVisitStorage.clientCode =
                            clientDetailsPayload.client_code
                        localStorage.setItem(
                            constants.CLIENT_FOR_VISIT_STORE,
                            JSON.stringify(parsedVisitStorage)
                        )
                    }
                    dispatch({
                        type: EnrollmentEnum.CLIENT_DETAILS_PAYLOAD,
                        payload: clientDetailsPayload,
                        gpsCoords
                    })

                    sendMessageToWorker({
                        'delete blank id': clientDetailsPayload.client_code
                    })
                }
            }

            if (canUseRxDb()) {
                const database = await Database.get()

                // get client from indexedDB
                const indexedClient = await database?.clients
                    .findOne({
                        selector: {
                            client_code: clientDetailsPayload?.client_code
                        }
                    })
                    .exec()
                if (indexedClient) {
                    indexedClient.patch({
                        ...clientDetailsPayload
                    })
                } else {
                    //! STORE CLIENT DETAILS PAYLOAD
                    database?.clients.upsert(
                        updateClientPayload(
                            clientDetailsPayload as ClientDetailsPayloadInterface
                        )
                    )
                }
            }

            const response = await connectApi.post(
                CLIENT_DETAILS,
                clientDetailsPayload
            )
            dispatch({
                type: EnrollmentEnum.SUBMIT_CLIENT_DETAILS_ENROLLMENT,
                payload: response.data
            })
        } catch (error: unknown) {
            console.log('error', error)

            if (error instanceof ErrorResponse) {
                dispatch({
                    type: EnrollmentEnum.FAIL_CLIENT_DETAILS_ENROLLMENT,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: EnrollmentEnum.FAIL_CLIENT_DETAILS_ENROLLMENT,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const saveLocalEnrollmentStateAction =
    (payload: {} | string | undefined, type: string) => (dispatch: Dispatch) =>
        dispatch({
            type,
            payload
        })
