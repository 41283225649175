import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useLocation, useNavigate } from 'react-router-dom'
import { Col, Container, Row, TopBar, Alert, Button, Modal } from 'components'
import { useTranslation } from 'react-i18next'
import { useGetStorage, useBindDispatch, useAppSelector } from 'hooks'
import {
    constants,
    abbreviateName,
    sendMessageToWorkerWithResponse,
    routes,
    permissionFlags,
    getConnectUser
} from 'helpers'
import { findPermission } from 'helpers/findPermission'
import { AddFarmerIcon } from 'assets'
import { FarmerIdModal } from 'pages/farmerId/FarmerIdModal'
import { ClientDetailsPayloadInterface, ClientType } from 'storeTypes'

export const ClientSearchResult = () => {
    const { pathname } = useLocation()

    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const searchResult = useGetStorage(constants.SEARCH_RESULT)
    const { veVillages } = getConnectUser()
    const navigate = useNavigate()
    const [errorMsg, setErrorMsg] = useState('')
    const [searchResultList, setSearchResultList] = useState<ClientType[]>([])
    const [showFarmerIdModal, setShowFarmerIdModal] = useState(false)
    const [selectedClient, setSelectedClient] =
        useState<ClientDetailsPayloadInterface>()

    const {
        villageSelectionInfoHandler,
        selectedClientHandler,
        getClientOrdersHandler
    } = useBindDispatch()

    const { searchResult: searchedClients } = useAppSelector(
        (state) => state.clientSearch
    )

    const { clientOrders } = useAppSelector((state) => state.clientOrders)

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)

    const canViewNewEnrollmentFlow = findPermission(
        userPermissions,
        constants.CAN_VIEW_NEW_ENROLLMENT_FLOW
    )

    const canUseFarmerId = findPermission(
        userPermissions,
        permissionFlags.canUseFarmerId
    )

    useEffect(() => {
        document.title = t('clientSearchResult')

        localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)

        if (!searchResult && searchedClients?.length >= 1) {
            localStorage.setItem(
                constants.SEARCH_RESULT,
                JSON.stringify(searchedClients)
            )
            setSearchResultList(searchedClients)
            setAnimate(true)
            return
        }

        if (!searchedClients && !searchResult) {
            // redirect to client search page
            navigate('/client-search')
        }
        setSearchResultList(searchResult)
        setAnimate(true)
        localStorage.removeItem(constants.CLIENT_FOR_ENROLLMENT_STORE)
    }, [searchedClients?.length])

    const searchResultClickHandler = (clientData: ClientType) => {
        setSelectedClient(
            clientData as unknown as ClientDetailsPayloadInterface
        )
        if (canUseFarmerId && !clientData.duplicate) {
            getClientOrdersHandler(clientData.client_code)
            return setShowFarmerIdModal(true)
        }
        const clientDetailBaseUrl = `/village/${clientData.village_id}/client/${clientData.client_code}`
        localStorage.setItem('client_details', JSON.stringify(clientData))
        const clientDetailUrl =
            clientData.all_enrollment <= 0
                ? clientDetailBaseUrl.concat('/previous')
                : clientDetailBaseUrl
        navigate(`${clientDetailUrl}?from=${pathname}`, { replace: true })
    }

    const singleResult = searchResultList?.map((searchResult, idx) => {
        return (
            <div
                className={
                    animationList
                        ? `card-search--parent animate-show-${idx} animate-showed-${idx}`
                        : `animate-show-${idx}`
                }
                data-testid="search-result"
                key={searchResult.client_code}
                onClick={() => searchResultClickHandler(searchResult)}
            >
                <div className="card-client-abbr card-client-abbr--name">
                    {abbreviateName(
                        `${searchResult.firstname} ${searchResult.lastname}`
                    )}
                </div>
                <div>
                    <p className="card-search--full-name">
                        {searchResult.firstname} {searchResult.lastname}
                    </p>
                    <p className="card-search--other-content">
                        ID {searchResult.client_code}
                    </p>
                    <p className="card-search--other-content">
                        {searchResult?.goal_items?.length &&
                        searchResult?.all_enrollment > 0
                            ? searchResult.goal_items[0].product.name
                            : '--'}
                    </p>
                    <p className="card-search--other-content">
                        {searchResult.recent_year_delivered
                            ? `${t('lastYearBook')}
                        ${new Date(
                            searchResult.recent_year_delivered
                        ).getFullYear()}`
                            : `${t('village.notDelivered')}`}
                    </p>
                    <p className="card-search--other-content">
                        {t('personalPhone')}:{' '}
                        {searchResult?.phone ? searchResult.phone : '--'}
                    </p>
                </div>
            </div>
        )
    })

    // eslint-disable-next-line no-unused-vars
    const enrollmentClickHandler = async () => {
        try {
            const totalBlankIdResponse = await sendMessageToWorkerWithResponse({
                'get blank id count': 'client needs a blank id'
            })

            if (totalBlankIdResponse?.blank_id_count < 1) {
                setTimeout(() => {
                    setErrorMsg('')
                }, 5000)
                return setErrorMsg(t('enrollment.BlankIDsDepleted'))
            }
            if (canViewNewEnrollmentFlow && veVillages.length === 1) {
                return navigate(
                    `/${routes.enroll}/${veVillages[0].sf_id}?from=${pathname}`
                )
            }
            const clientDetailsEnrollmentUrl = canViewNewEnrollmentFlow
                ? `/${routes.enroll}/`
                : '/enrollment/client-details?form_type=enrollment&village_id='
            if (veVillages?.length === 1) {
                return navigate(
                    `${clientDetailsEnrollmentUrl}${veVillages[0].sf_id}`
                )
            }
            villageSelectionInfoHandler({
                url: clientDetailsEnrollmentUrl
            })
            return navigate('/village/select-village')
        } catch (error) {
            setErrorMsg(t(error as string))
        }
    }

    const clientSearchResultPage = (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div ref={nodeRef}>
                <Container>
                    <Modal
                        showModal={showFarmerIdModal}
                        data-testid="farmer-id-modal"
                        position={'center'}
                        className="farmer-id--modal"
                        closeClickHandler={() => setShowFarmerIdModal(false)}
                        showCloseIconBtn
                    >
                        <FarmerIdModal
                            clientData={selectedClient as unknown as ClientType}
                            selectClientHandler={selectedClientHandler}
                            closeModalHandler={() =>
                                setShowFarmerIdModal(false)
                            }
                            clientOrders={clientOrders || []}
                        />
                    </Modal>
                    <Row
                        className="client-search--result-containter"
                        data-testid="search-container"
                    >
                        <Col md={12}>
                            <div className="card-search--page-title">
                                {!canUseFarmerId ? (
                                    <h2>
                                        {t('matchedResult')}{' '}
                                        {searchResult?.length > 0
                                            ? `(${searchResult.length})`
                                            : ''}
                                    </h2>
                                ) : (
                                    <p className="card-search--title">
                                        {t('clientSearchResult')}
                                    </p>
                                )}
                            </div>
                        </Col>
                        <Col md={12}>
                            {singleResult?.length > 0 ? (
                                singleResult
                            ) : (
                                <p className="no-result">{t('noResult')}</p>
                            )}
                            {!canUseFarmerId ? (
                                <div
                                    className="card-search--add-new-client"
                                    data-testid="add-new-client-link"
                                    onClick={() => enrollmentClickHandler()}
                                >
                                    <p className="add-new-client-link">
                                        {t('unavailableClient')}
                                    </p>
                                </div>
                            ) : (
                                <Row>
                                    <Col md={12}>
                                        <Button
                                            style="primary"
                                            size="xl"
                                            icon={AddFarmerIcon}
                                            onClick={enrollmentClickHandler}
                                        >
                                            {t('enrollment.addNew')}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )
    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar
                        back={true}
                        backNavigation={() => navigate('/home')}
                    >
                        {t('result')}
                    </TopBar>
                </Container>
            </div>
            {clientSearchResultPage}
            {errorMsg && (
                <Alert type="floating" status={'error'}>
                    {errorMsg}
                </Alert>
            )}
        </>
    )
}
