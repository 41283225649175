import { ConnectUserType } from './appTypes'
import { constants } from './constants'
import { useGetStorage } from 'hooks'

export const getConnectUser = () => {
    return (useGetStorage(constants.CONNECT_USER) || {
        country: '',
        exp: 0,
        iat: 0,
        language: '',
        role: '',
        sf_id: '',
        supervisor_id: '',
        token: '',
        user_id: 0,
        username: '',
        veVillages: [],
        zoneName: '',
        zoneSFID: '',
        zone_code: '',
        staff_code: ''
    }) as ConnectUserType
}
