import React from 'react'
import CountUp from 'react-countup'
import { MetricType } from './circleMetricsTypes'

export const CircleMetric: React.FC<MetricType> = ({
    label = '',
    value = 0,
    color = '#16a34a'
}) => {
    return (
        <div className="metrics--summary" aria-label="circle-metric">
            <div className="metrics--summary-circle">
                <p className="metrics--circle-inner-text" style={{ color }}>
                    <CountUp end={value as number} separator=" " />
                </p>
            </div>
            <p className="metrics--circle-text">{label}</p>
        </div>
    )
}
