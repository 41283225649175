import { ActionTypes } from '../actions'
import { groupBy } from 'lodash'
import { ClientType } from '../storeTypes/clientSearchTypes'
import { ClientsActionEnum, ClientActionType } from '../storeTypes/clientTypes'

type ClientsStateItem = {
    loading: boolean
    clientList: ClientType[]
    error?: string
    selectedClient?: ClientType
}

type PreviousClientsStateItem = {
    loading: boolean
    previousClientList: ClientType[]
    error?: string
    prevSelectedClient?: ClientType
}

type VillageClientState = {
    [key: string]: ClientsStateItem
}

type VillagePreviousClientState = {
    [key: string]: PreviousClientsStateItem
}

const clientsInitialState: VillageClientState = {}

export const villageClientReducer = (
    state = clientsInitialState,
    action: ClientActionType
): VillageClientState => {
    switch (action.type) {
        case ClientsActionEnum.FETCH_CLIENT_LIST: {
            return {
                ...state,
                [action.villageId ?? '']: {
                    clientList: action.payload ?? [],
                    loading: false,
                    error: ''
                }
            }
        }
        case ClientsActionEnum.FETCHING_CLIENT_LIST:
            return {
                ...state,
                [action.villageId ?? '']: {
                    loading: true,
                    error: '',
                    clientList: []
                }
            }
        case ClientsActionEnum.FETCHING_BG_CLIENT_LIST: {
            const currentVillageState =
                state[action.villageId ?? ''] || ({} as ClientsStateItem)
            return {
                ...state,
                [action.villageId ?? '']: {
                    loading: currentVillageState?.clientList?.length <= 0,
                    clientList: currentVillageState?.clientList,
                    error: ''
                }
            }
        }
        case ClientsActionEnum.FAIL_CLIENT_LIST_REQUEST:
            return {
                ...state,
                [action.villageId ?? '']: {
                    loading: false,
                    clientList: [],
                    error: action.payload
                }
            }
        case ClientsActionEnum.SELECT_VILLAGE_CLIENT: {
            const { clientId, villageId } = action.payload ?? {
                clientId: '',
                villageId: ''
            }
            const clientData = state[villageId]?.clientList?.find(
                (client: ClientType) => client.client_code === clientId
            )
            const clientListState = state[villageId] || {}
            clientListState.selectedClient = clientData
            return { ...state, [villageId]: clientListState }
        }
        case ClientsActionEnum.RESET_SELECT_VILLAGE_CLIENT: {
            const villageId = action.villageId ?? ''
            const clientListState = state[villageId] || {}
            clientListState.selectedClient = undefined
            return {
                ...state,
                [villageId]: clientListState
            }
        }
        case ClientsActionEnum.SELECTED_CLIENT: {
            const villageId = action.villageId ?? ''
            const clientListState = state[villageId] || {}
            clientListState.selectedClient = action.payload?.[0]
            return {
                ...state,
                [villageId]: clientListState
            }
        }
        default:
            return state
    }
}

const previousClientsInitialState: VillagePreviousClientState = {}

export const villagePreviousClientReducer = (
    state = previousClientsInitialState,
    action: ClientActionType
): VillagePreviousClientState => {
    switch (action.type) {
        case ClientsActionEnum.FETCHING_PREVIOUS_CLIENT:
            return {
                ...state,
                [action.villageId ?? '']: {
                    previousClientList: [],
                    loading: true,
                    error: ''
                }
            }
        case ClientsActionEnum.FETCH_PREVIOUS_CLIENT_LIST: {
            const villageId = action.villageId ?? ''
            return {
                ...state,
                [villageId]: {
                    previousClientList: action.payload ?? [],
                    loading: false,
                    error: ''
                }
            }
        }
        case ClientsActionEnum.FAIL_PREVIOUS_CLIENT_REQUEST:
            return {
                ...state,
                [action.villageId ?? '']: {
                    previousClientList: [],
                    error: action.payload,
                    loading: false
                }
            }
        case ClientsActionEnum.PREV_SELECT_VILLAGE_CLIENT: {
            const { clientId, villageId } = action.payload ?? {
                clientId: '',
                villageId: ''
            }
            const prevClientData = state[villageId]?.previousClientList?.find(
                (client: ClientType) => client.client_code === clientId
            )
            const prevClientListState = state[villageId] || {}
            return {
                ...state,
                [villageId]: {
                    ...prevClientListState,
                    prevSelectedClient: prevClientData
                }
            }
        }
        case ClientsActionEnum.SELECTED_PREV_CLIENT: {
            const villageId = action.villageId ?? ''
            const prevClientListState = state[villageId] || {}
            return {
                ...state,
                [villageId]: {
                    ...prevClientListState,
                    prevSelectedClient: action.payload?.[0]
                }
            }
        }
        default:
            return state
    }
}

export const singleClientReducer = (
    state: ClientsStateItem = { loading: false, error: '', clientList: [] },
    action: ClientActionType
): ClientsStateItem => {
    switch (action.type) {
        case ClientsActionEnum.FETCHING_SINGLE_CLIENT:
            return { loading: true, error: '', clientList: [] }
        case ClientsActionEnum.FETCHED_SINGLE_CLIENT:
            return {
                loading: false,
                error: '',
                clientList: action.payload ?? []
            }
        case ClientsActionEnum.FAIL_SINGLE_CLIENT_REQUEST:
            return { loading: false, error: action.payload, clientList: [] }
        case ClientsActionEnum.CLEAR_FETCHED_SINGLE_CLIENT:
            return { loading: false, error: '', clientList: [] }
        default:
            return state
    }
}

type openVillagePageState = {
    openVillagePageData?: {
        villageId: string
        filterOptions?: string[]
        clientTab: string
    }
}

const openVillagePageInitialState: openVillagePageState = {}

export const openVillagePageReducer = (
    state = openVillagePageInitialState,
    action: {
        type: string
        payload?: {
            villageId: string
            filterOptions: string[]
            clientTab: string
        }
    }
): openVillagePageState => {
    switch (action.type) {
        case ActionTypes.OPENING_VILLAGE_PAGE:
            return {
                openVillagePageData: {
                    villageId: action.payload?.villageId ?? '',
                    filterOptions: action.payload?.filterOptions,
                    clientTab: action.payload?.clientTab ?? ''
                }
            }

        default:
            return state
    }
}

export const villageSelectionInfoReducer = (
    state = { url: '' },
    action: { type: string; payload?: { url: string } }
): { url: string } => {
    switch (action.type) {
        case ActionTypes.SET_SELECTION_VILLAGE_URL:
            return {
                url: action.payload?.url || ''
            }
        case ActionTypes.CLEAR_SELECTION_VILLAGE_INFO:
            return { url: '' }
        default:
            return state
    }
}

type VeClientListState = {
    clientList: ClientType[]
    prevClientList: ClientType[]
}

const initialVeClientListState: VeClientListState = {
    clientList: [],
    prevClientList: []
}

export const veClientListReducer = (
    state = initialVeClientListState,
    action: {
        type: string
        payload?: { clientList: ClientType[]; prevClientList: ClientType[] }
    }
): VeClientListState => {
    switch (action.type) {
        case ClientsActionEnum.FETCH_VE_CLIENT_LIST:
            return {
                clientList: action.payload?.clientList ?? [],
                prevClientList: action.payload?.prevClientList ?? []
            }
        default:
            return state
    }
}

type VillagesClientCountState = {
    totalClientObj: {}
}

type VillageCountAction = {
    type: string
    payload?: ClientType[]
}

export const multipleVillagesCountReducer = (
    state: VillagesClientCountState = { totalClientObj: {} },
    action: VillageCountAction
): VillagesClientCountState => {
    switch (action.type) {
        case ActionTypes.MULTIPLE_VILLAGE_COUNT: {
            const clientsByVillage = groupBy(action.payload, 'village_id')
            const villageClientCounts: {
                [key: string]: {
                    NbCurrentClients: number
                    NbPrevClients: number
                }
            } = {}
            for (const [key, value] of Object.entries(clientsByVillage)) {
                const [NbCurrentClients, NbPrevClients] = value.reduce(
                    ([current, prev], client) =>
                        (client?.all_enrollment ?? 0) > 0
                            ? [current + 1, prev]
                            : [current, prev + 1],
                    [0, 0]
                )

                villageClientCounts[key] = {
                    NbCurrentClients,
                    NbPrevClients
                }
            }
            return {
                totalClientObj: villageClientCounts
            }
        }
        default:
            return state
    }
}
