import React, { useEffect, useMemo, useState } from 'react'

import { compact } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useBindDispatch } from 'hooks'
import {
    ClientDetailsPayloadInterface,
    InteractionPayloadType,
    TargetPayloadType
} from 'storeTypes'
import {
    constants,
    getConnectUser,
    searchHandler,
    sortTargetList
} from '../../helpers'
import { TargetListInterface } from './targetTypes'

import { NotAvailableIcon } from 'assets'
import { Col, Row, Spinner, TextInput } from '../../components'
import { RootState } from '../../store/configStore'
import { Target } from './Target'
import { TargetFilter } from './TargetFilter'

export const TargetList = ({
    targetScrollRef,
    veCode
}: TargetListInterface) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {
        fetchVeClientListHandler,
        fetchTargetListHandler,
        processTargetListHandler,
        filterTargetListHandler,
        getInteractionsHandler
    } = useBindDispatch()

    const { clientList, prevClientList } = useSelector(
        ({ veClientList }: RootState) => veClientList
    )

    const { veVillages } = getConnectUser()

    const {
        loading: targetListLoading,
        targetList,
        processing: targetListProcessing,
        processedTargetList,
        selectedFilter: storeSelectedFilter
    } = useSelector(({ targetList }: RootState) => targetList)

    const [searchResults, setSearchResults] = useState(processedTargetList)

    const { interactions = [] } = useSelector(
        ({ veInteractions }: RootState) => veInteractions
    )
    const { loading: loadingInteractions } = useSelector(
        ({ interactions }: RootState) =>
            interactions[veVillages[0]?.sf_id] || {}
    )
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if ((processedTargetList?.length || 0) < 1) {
            fetchTargetListHandler({ veCode })
        }
        localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)
    }, [])

    useEffect(() => {
        if (targetList.length > 0 && !loadingInteractions) {
            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )
            getInteractionsHandler({
                clientCodes,
                surveyName: constants.TARGET_LIST_SURVEY_NAME,
                surveyVersion: constants.TARGET_LIST_SURVEY_VERSION
            })
            fetchVeClientListHandler(veVillages)
        }
    }, [targetList, loadingInteractions])

    useEffect(() => {
        clientSearchHandler()
    }, [searchValue, processedTargetList])

    useEffect(() => {
        if (
            (clientList.length > 0 || prevClientList.length > 0) &&
            !loadingInteractions &&
            targetList.length > 0
        ) {
            const clients = [
                ...Object.values(clientList),
                ...Object.values(prevClientList)
            ].flat() as unknown as ClientDetailsPayloadInterface[]

            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )
            processTargetListHandler({
                targetList,
                clients,
                interactions: (interactions as InteractionPayloadType[]).filter(
                    (interaction) =>
                        clientCodes.includes(interaction.client_code) &&
                        interaction.survey_version ===
                            constants.TARGET_LIST_SURVEY_VERSION
                ),
                selectedFilter: storeSelectedFilter
            })
        }
    }, [interactions, targetList, clientList, prevClientList])

    const onSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { value } = e.target
        setSearchValue(value)
    }

    const clientSearchHandler = () => {
        if (searchValue.trim().length > 2) {
            return setSearchResults(
                searchHandler(processedTargetList || [], {
                    fullname: searchValue,
                    reversed_fullname: searchValue,
                    client_code: searchValue
                })
            )
        }
        setSearchResults(processedTargetList)
    }

    const targetListClickHandler = (
        villageId = '',
        clientCode = '',
        clientType = ''
    ) => {
        const clientTypeRoute = clientType === 'previous' ? 'previous' : ''

        navigate(
            `/village/${villageId}/client/${clientCode}/${clientTypeRoute}`
        )
    }

    const sortedTargetList = useMemo(
        () => sortTargetList(searchResults || []),
        [searchResults]
    )

    return targetListLoading || targetListProcessing ? (
        <div className="target-list--spinner-parent">
            <Spinner
                data-testid="loading-indicator"
                size="50"
                pageSpinner={false}
                fullscreen={false}
            />
        </div>
    ) : (
        <div
            ref={targetScrollRef}
            className="target-list"
            data-testid="target-list"
        >
            <Row>
                <Col md={12}>
                    <h2 className="target-list--header target-list--parent">
                        {t('targetList.targetListHeaderTitle')}
                    </h2>
                    <TextInput
                        placeholder={t('targetList.searchPlaceholder')}
                        onChange={(e) => onSearchInput(e)}
                        value={searchValue}
                        data-testid="search-input"
                        id="client-list-search"
                        className="target-list--search-input"
                    />
                    <div>
                        <TargetFilter filterHandler={filterTargetListHandler} />
                    </div>
                </Col>
                <div className="card-session target-list--card-session">
                    <div>
                        {sortedTargetList.length > 0 ? (
                            sortedTargetList.map((singleTarget) => (
                                <Target
                                    targetData={singleTarget}
                                    key={singleTarget.uuid}
                                    targetOnClick={() => {
                                        targetListClickHandler(
                                            singleTarget.village_id,
                                            singleTarget.client_code,
                                            singleTarget.client_type
                                        )
                                    }}
                                    filter={storeSelectedFilter || ''}
                                />
                            ))
                        ) : (
                            <div className="target-list--empty-list">
                                <div
                                    className="target-list--empty-list-icon"
                                    data-testid="not-available-icon"
                                >
                                    <NotAvailableIcon />
                                </div>
                                <div className="target-list--empty-list-text">
                                    <p>{t('targetList.notAvailable')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Row>
        </div>
    )
}
