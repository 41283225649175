import { ClientType } from '../storeTypes/clientSearchTypes'

export enum ClientStatus {
    POTENTIAL = 'POTENTIAL_CLIENT',
    PAYMENT_WITHOUT_PACKAGE = 'PAYMENT_NO_PACKAGE',
    PACKAGE_WITHOUT_PAYMENT = 'PACKAGE_NO_PAYMENT',
    ENROLLED = 'ENROLLED',
    FINISHED = 'FINISHED',
    DUPLICATE = 'DUPLICATE'
}

export enum ClientsActionEnum {
    FAIL_PREVIOUS_CLIENT_REQUEST = 'fail previous client request',
    FAIL_CLIENT_LIST_REQUEST = 'fail client request',
    FETCHED_BG_CLIENT_LIST = 'fetched background client list',
    FETCHING_BG_CLIENT_LIST = 'fetching background client list',
    FETCHING_PREVIOUS_CLIENT = 'fetching previous client',
    FETCHING_CLIENT_LIST = 'fetching village client data',
    FETCH_CLIENT_LIST = 'fetch client list data',
    FETCH_PREVIOUS_CLIENT_LIST = 'fetch previous client',
    RESET_CLIENT_LIST = 'reset client list',
    SELECT_VILLAGE_CLIENT = 'select village client',
    RESET_SELECT_VILLAGE_CLIENT = 'reset select village client',
    SELECTED_CLIENT = 'selected client',
    PREV_SELECT_VILLAGE_CLIENT = 'previous select village client',
    SELECTED_PREV_CLIENT = 'selected previous client',
    FETCHING_SINGLE_CLIENT = 'fetching single client',
    FETCHED_SINGLE_CLIENT = 'fetched single client',
    FAIL_SINGLE_CLIENT_REQUEST = 'fail single client request',
    CLEAR_FETCHED_SINGLE_CLIENT = 'clear fetched single client',
    FETCH_VE_CLIENT_LIST = 'fetch ve client list'
}

type FailedClientRequestType = {
    type:
        | ClientsActionEnum.FAIL_CLIENT_LIST_REQUEST
        | ClientsActionEnum.FAIL_PREVIOUS_CLIENT_REQUEST
        | ClientsActionEnum.FAIL_SINGLE_CLIENT_REQUEST
    payload?: string
    villageId?: string
}

type FetchClientType = {
    type:
        | ClientsActionEnum.FETCH_CLIENT_LIST
        | ClientsActionEnum.FETCHING_CLIENT_LIST
        | ClientsActionEnum.FETCHED_BG_CLIENT_LIST
        | ClientsActionEnum.FETCHING_BG_CLIENT_LIST
        | ClientsActionEnum.FETCHING_PREVIOUS_CLIENT
        | ClientsActionEnum.FETCH_PREVIOUS_CLIENT_LIST
        | ClientsActionEnum.SELECTED_CLIENT
        | ClientsActionEnum.SELECTED_PREV_CLIENT
        | ClientsActionEnum.FETCHED_SINGLE_CLIENT
        | ClientsActionEnum.FETCHING_SINGLE_CLIENT
        | ClientsActionEnum.CLEAR_FETCHED_SINGLE_CLIENT
    payload?: ClientType[]
    villageId?: string
}

type SelectionClientType = {
    type:
        | ClientsActionEnum.SELECT_VILLAGE_CLIENT
        | ClientsActionEnum.PREV_SELECT_VILLAGE_CLIENT
    payload?: { clientId: string; villageId: string }
}

type ResetClientSelectionType = {
    type: ClientsActionEnum.RESET_SELECT_VILLAGE_CLIENT
    villageId?: string
}

export type ClientActionType =
    | FetchClientType
    | SelectionClientType
    | FailedClientRequestType
    | ResetClientSelectionType
