import {
    FcMetricsPayload,
    FcMetricsEnum,
    FetchFcMetricsAction
} from '../storeTypes'

interface FcMetricsState {
    loading: boolean
    data: FcMetricsPayload | undefined
    error: string | undefined
}

const initialState: FcMetricsState = {
    loading: true,
    data: undefined,
    error: undefined
}

export const fcMetricsReducer = (
    state = initialState,
    action: FetchFcMetricsAction
): FcMetricsState => {
    switch (action.type) {
        case FcMetricsEnum.FETCH_FC_METRICS_SUCCESS:
            return {
                ...state,
                data: action.payload as FcMetricsPayload,
                loading: false,
                error: undefined
            }

        case FcMetricsEnum.FETCHING_FC_METRICS:
            return { loading: true, error: undefined, data: undefined }

        case FcMetricsEnum.FETCH_FC_METRICS_FAILURE:
            return {
                loading: false,
                data: undefined,
                error: action.payload
            }

        default:
            return state
    }
}
