import { useEffect, useState, useCallback } from 'react'
import { useAppSelector } from 'hooks'
import { ClientType } from 'storeTypes'
import { constants } from 'helpers/constants'
import { useGetStorage } from 'hooks/useGetStorage'
import { useSearchParams } from 'react-router-dom'

const getLocalClient = (clientId?: string) => {
    const localClient = useGetStorage(constants.CLIENT_DETAIL_LOCAL_STORE)

    if (localClient?.client_code === clientId) {
        return localClient
    }

    return null
}

// TODO: Consolidate client details data from all sources:
// Redux -
//   * villageClientList.clientData (IndexedDB)
//   * singleClient.client (HTTP)
//   * updatePackages.enrollmentPayload
// localStorage -
//   * constants.CLIENT_DETAIL_LOCAL_STORE
//   * constants.CLIENT_FOR_ENROLLMENT_STORE
//   * constants.CLIENT_FOR_VISIT_STORE
export const useGetClient = (villageId: string, clientId?: string) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [client, setClient] = useState<ClientType>(getLocalClient(clientId))

    const { enrollmentPayload } = useAppSelector(
        (state) => state.updatePackages
    )
    const { loading: loadingClient, clientList: singleClient } = useAppSelector(
        (state) => state.singleClient
    )
    const { selectedClient } = useAppSelector(
        (state) => state.villageClientList[villageId] || {}
    )

    const updateClient = useCallback((updatedClient: ClientType) => {
        setClient(updatedClient)

        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(updatedClient)
        )
    }, [])

    useEffect(() => {
        if (
            enrollmentPayload?.client_code &&
            enrollmentPayload?.client_code === clientId
        ) {
            updateClient({
                ...enrollmentPayload,
                ...client,
                allEnrollment: enrollmentPayload.all_enrollment,
                fullname: `${enrollmentPayload.firstname} ${enrollmentPayload.lastname}`,
                zone: enrollmentPayload.zone
            })
        }
    }, [enrollmentPayload])

    useEffect(() => {
        const foundClient = singleClient?.find(
            (client: ClientType) => client.client_code === clientId
        )

        if (foundClient) {
            searchParams.delete('reload')
            setSearchParams(searchParams)

            updateClient({
                ...foundClient,
                fullname: `${foundClient.firstname} ${foundClient.lastname}`
            })
        }
    }, [loadingClient, singleClient])

    useEffect(() => {
        if (
            selectedClient &&
            selectedClient.client_code &&
            selectedClient.client_code !== client?.client_code &&
            selectedClient.client_code === clientId
        ) {
            updateClient({
                ...selectedClient,
                fullname: `${selectedClient.firstname} ${selectedClient.lastname}`
            })
        }
    }, [selectedClient])

    return {
        client,
        updateClient,
        loadingClient
    }
}
