/* eslint-disable no-useless-escape */
import connectApi from '../api/connectApi'
import { ActionTypes } from '../actions'
import {
    constants,
    sendMessageToWorkerWithResponse,
    internetConnect,
    fetchClientIndexDbClientList,
    canUseRxDb,
    partitionClients,
    bulkInsertClients,
    getConnectUser
} from 'helpers'
import { groupBy } from 'lodash'
import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from '../datastores/LocalDataExpiration'
import { ClientsActionEnum } from 'storeTypes'

const dispatchClientList = (
    clientData = [],
    prevClientData = [],
    villageId,
    dispatch
) => {
    // fetch new client data
    const enrich = (clients) =>
        clients.map((client) => ({
            ...client,
            village_id: villageId
        }))

    dispatch({
        type: ClientsActionEnum.FETCH_CLIENT_LIST,
        payload: enrich(clientData),
        villageId
    })
    dispatch({
        type: ClientsActionEnum.FETCH_PREVIOUS_CLIENT_LIST,
        payload: enrich(prevClientData),
        villageId
    })
}

export const refreshVillageClientListAction =
    ({ villageId, clients }) =>
    async (dispatch) => {
        if (canUseRxDb()) {
            const storedFetchedClientData = await bulkInsertClients([
                ...clients
            ])
            const clientsByVillageId = groupBy(
                storedFetchedClientData,
                '_data.village_id'
            )
            const { currentClients: client, previousClients: previous } =
                partitionClients(clientsByVillageId[villageId] || [])
            const clientListData = client.map((item) => item._data)
            const prevClientListData = previous.map((item) => item._data)

            dispatchClientList(
                clientListData,
                prevClientListData,
                villageId,
                dispatch
            )
        } else {
            const clientsByVillageId = await sendMessageToWorkerWithResponse({
                'send client list data': {
                    villageId,
                    clients
                }
            })
            const { client, previous } = clientsByVillageId[villageId]

            dispatchClientList(client, previous, villageId, dispatch)
        }
    }

const storeVillageClientList = (villages, dispatch) => {
    const { ALL_CLIENT_LIST } = constants.endpoints
    let allFetchResult = {}

    if (canUseRxDb()) {
        fetchClientIndexDbClientList(
            dispatchClientList,
            dispatch,
            villages[0]?.sf_id
        ).then((storedFetchedClientData) => {
            const clientsByVillageId = groupBy(
                storedFetchedClientData,
                '_data.village_id'
            )
            for (const [villageId, clients] of Object.entries(
                clientsByVillageId
            )) {
                const { currentClients: client, previousClients: previous } =
                    partitionClients(clients || [])
                dispatchClientList(
                    client.map((item) => item._data),
                    previous.map((item) => item._data),
                    villageId,
                    dispatch
                )
            }
        })
    } else {
        villages.forEach((village) => {
            connectApi
                .get(ALL_CLIENT_LIST, {
                    params: {
                        village: village.sf_id
                    }
                })
                .then(async (response) => {
                    updateLocalDataExpirationTime(
                        constants.CLIENT_LIST_EXPIRATION_NAME,
                        village.sf_id,
                        constants.CLIENT_LIST_EXPIRATION_DURATION
                    )

                    const clientData = await sendMessageToWorkerWithResponse({
                        'send client list data': {
                            villageId: village.sf_id,
                            clients: response.data
                        }
                    })

                    allFetchResult = {
                        ...allFetchResult,
                        ...clientData
                    }
                    for (const [
                        villageId,
                        { client, previous }
                    ] of Object.entries(allFetchResult)) {
                        dispatchClientList(
                            client,
                            previous,
                            villageId,
                            dispatch
                        )
                    }
                })
        })
    }
}

export const storeVillageClientListAction =
    ({ villages, dispatchLoadingAction = true }) =>
    async (dispatch) => {
        const isInternetConnection = internetConnect()

        if (!isInternetConnection) {
            return
        }

        if (dispatchLoadingAction) {
            villages?.map((village) =>
                dispatch({
                    type: ClientsActionEnum.FETCHING_BG_CLIENT_LIST,
                    villageId: village.sf_id
                })
            )
        }

        try {
            storeVillageClientList(villages, dispatch)
        } catch ({ response }) {
            dispatch({
                type: ClientsActionEnum.FAIL_CLIENT_LIST_REQUEST,
                payload: response?.data?.detail
            })
        }
    }

const fetchFromIndexStore = async (villageId) => {
    // get client data from indexDb
    const storedClientData = await sendMessageToWorkerWithResponse({
        'get client data': {
            villageIds: [villageId],
            clientType: constants.CLIENT_TYPES.CURRENT_CLIENT,
            filter: 'village'
        }
    })
    // get previous client data from indexDb
    const storedPrevClientData = await sendMessageToWorkerWithResponse({
        'get client data': {
            villageIds: [villageId],
            clientType: constants.CLIENT_TYPES.PREVIOUS_CLIENT,
            filter: 'village'
        }
    })
    return {
        clientList: storedClientData.storedData,
        prevClientList: storedPrevClientData.storedData
    }
}

// get both client and previous client data
export const villageClientListAction =
    ({ villageId }) =>
    async (dispatch) => {
        dispatch({
            type: ClientsActionEnum.FETCHING_CLIENT_LIST,
            villageId
        })

        const isInternetConnection = internetConnect()

        try {
            const { veVillages } = getConnectUser()

            if (
                hasLocalDataExpired(
                    constants.CLIENT_LIST_EXPIRATION_NAME,
                    villageId
                ) &&
                isInternetConnection
            ) {
                storeVillageClientListAction({
                    villages: veVillages,
                    dispatchAction: false
                })
            }

            let clientListData = []
            let prevClientListData = []

            if (canUseRxDb()) {
                const storedFetchedClientData =
                    await fetchClientIndexDbClientList(
                        dispatchClientList,
                        dispatch,
                        villageId
                    )
                const clientsByVillageId = groupBy(
                    storedFetchedClientData,
                    '_data.village_id'
                )
                const { currentClients: client, previousClients: previous } =
                    partitionClients(clientsByVillageId[villageId] || []) || {
                        client: [],
                        previous: []
                    }
                clientListData = client.map((item) => item._data)
                prevClientListData = previous.map((item) => item._data)
            } else {
                const { clientList, prevClientList } =
                    await fetchFromIndexStore(villageId)
                clientListData = clientList
                prevClientListData = prevClientList
            }

            dispatchClientList(
                [...(clientListData || [])],
                [...(prevClientListData || [])],
                villageId,
                dispatch
            )
        } catch ({ response }) {
            dispatch({
                type: ClientsActionEnum.FAIL_CLIENT_LIST_REQUEST,
                payload: response?.data?.detail,
                villageId
            })
        }
    }

export const getVeClientListAction = (villages) => async (dispatch) => {
    const mergedClientList = []
    const mergedPrevClientList = []
    await Promise.all(
        villages.map(async (village) => {
            const { clientList, prevClientList } = await fetchFromIndexStore(
                village.sf_id
            )
            mergedClientList.push(...clientList)
            mergedPrevClientList.push(...prevClientList)
        })
    )
    dispatch({
        type: ClientsActionEnum.FETCH_VE_CLIENT_LIST,
        payload: {
            clientList: mergedClientList,
            prevClientList: mergedPrevClientList
        }
    })
}

export const selectVillageClientAction =
    ({ clientId, villageId }) =>
    (dispatch) => {
        dispatch({
            type: ClientsActionEnum.SELECT_VILLAGE_CLIENT,
            payload: { clientId, villageId }
        })
    }

export const resetSelectVillageClientAction = (villageId) => (dispatch) => {
    dispatch({
        type: ClientsActionEnum.RESET_SELECT_VILLAGE_CLIENT,
        villageId
    })
}

export const selectVillagePrevClientAction =
    ({ clientId, villageId }) =>
    (dispatch) => {
        dispatch({
            type: ClientsActionEnum.PREV_SELECT_VILLAGE_CLIENT,
            payload: { clientId, villageId }
        })
    }

export const selectedClientAction =
    ({ clientData, clientType }) =>
    (dispatch) => {
        dispatch({
            type:
                clientType === 'current'
                    ? ClientsActionEnum.SELECTED_CLIENT
                    : ClientsActionEnum.SELECTED_PREV_CLIENT,
            payload: [clientData],
            villageId: clientData.village_id
        })
    }

export const openVillagePageAction =
    ({ filterOptions, villageId, clientTab }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.OPENING_VILLAGE_PAGE,
            payload: { filterOptions, villageId, clientTab }
        })
    }

export const villageSelectionInfoAction =
    ({ url }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.SET_SELECTION_VILLAGE_URL,
            payload: { url }
        })
    }

export const updateSingleClientBalAndSmsAction =
    ({ villageId, clientId }) =>
    async (dispatch) => {
        dispatch({ type: ClientsActionEnum.FETCHING_SINGLE_CLIENT })

        try {
            const response = await connectApi.get(
                constants.endpoints.ALL_CLIENT_LIST,
                {
                    params: {
                        client_codes: clientId
                    }
                }
            )

            const transactionsResponse = await connectApi.get(
                constants.endpoints.TRANSACTIONS,
                {
                    params: {
                        client_codes: clientId
                    }
                }
            )

            let clientData = {}
            if (response.data.length > 0) {
                response.data[0].villageName = response.data[0].village
                response.data[0].village_id = villageId
                response.data[0]._is_new = true
                response.data[0].smss = transactionsResponse.data
                clientData = { ...response.data[0] }
            }

            sendMessageToWorkerWithResponse({
                'update balance and sms for client data': {
                    payload: clientData
                }
            })

            dispatch({
                type: ClientsActionEnum.FETCHED_SINGLE_CLIENT,
                payload: response.data,
                villageId
            })
        } catch (error) {
            dispatch({
                type: ClientsActionEnum.FAIL_SINGLE_CLIENT_REQUEST,
                payload: error?.response?.data?.detail,
                villageId
            })
        }
    }

export const clearVillageSelectionInfoAction = () => (dispatch) => {
    dispatch({
        type: ActionTypes.CLEAR_SELECTION_VILLAGE_INFO
    })
}

export const clearSingleClientDataAction = () => (dispatch) => {
    dispatch({
        type: ClientsActionEnum.CLEAR_FETCHED_SINGLE_CLIENT
    })
}

export const handleMultipleVillagesCountAction =
    (villageIds) => async (dispatch) => {
        // get client data from indexDb
        const storedClientData = await sendMessageToWorkerWithResponse({
            'get client data': {
                filter: 'village',
                clientType: constants.CLIENT_TYPES.ALL_CLIENT,
                villageIds
            }
        })

        dispatch({
            type: ActionTypes.MULTIPLE_VILLAGE_COUNT,
            payload: storedClientData.storedData
        })
    }
